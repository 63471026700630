<template>
  <v-container fluid app>
    <page-title title="Digital Ecosystem" :useBackBtn="true"></page-title>

    <div style="padding-top: 10px" class="flex justify-content-center padding">
      <v-row>
        <v-col md="3" cols="12">
          <v-card
            link
            to="/components/component_subcomponents"
            class="mx-auto"
            max-width="100%"
            outlined
            color="success"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div style="color: white" class="text-overline mb-4">
                  Sub-Components
                </div>
                <v-list-item-title
                  style="color: white; font-weight: bold"
                  class="text-h5 mb-1"
                >
                  2
                </v-list-item-title>
                <v-list-item-subtitle style="color: white">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <!-- 
            to="/components/component_projects" -->
          <v-card
            link
            class="mx-auto"
            max-width="100%"
            outlined
            color="success"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div style="color: white" class="text-overline mb-4">
                  Projects
                </div>
                <v-list-item-title
                  style="color: white; font-weight: bold"
                  class="text-h5 mb-1"
                >
                  12
                </v-list-item-title>
                <v-list-item-subtitle style="color: white">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto"
            max-width="100%"
            outlined
            color="success"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div style="color: white" class="text-overline mb-4">
                  Planned Budget
                </div>
                <v-list-item-title
                  style="color: white; font-weight: bold"
                  class="text-h5 mb-1"
                >
                  69,420,000,000 Tsh ($30 Mill)
                </v-list-item-title>
                <v-list-item-subtitle style="color: white">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto"
            max-width="100%"
            outlined
            color="success"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div style="color: white" class="text-overline mb-4">
                  Disbursed Amount
                </div>
                <v-list-item-title
                  style="color: white; font-weight: bold"
                  class="text-h5 mb-1"
                >
                  10,420,000,000 Tsh
                </v-list-item-title>
                <v-list-item-subtitle style="color: white">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <DetailsTable :appeal="appeal" />
        </v-col>
      </v-row>
    </div>

    <div class="d-flex col justify-end align-center right">
      <v-tooltip bottom color="orange">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="addProjectDialog(true)"
            v-bind="attrs"
            v-on="on"
            title=""
            right
            fab
            small
            color="orange white--text"
            style="margin: 5px"
            ><v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add New Sub-Component</span>
      </v-tooltip>
      <v-tooltip bottom color="orange">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="addProjectDialog(true)"
            v-bind="attrs"
            v-on="on"
            title=""
            right
            fab
            small
            color="success white--text"
            style="margin: 5px"
            ><v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add New Project</span>
      </v-tooltip>

      <!-- <v-tooltip bottom color="orange">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            link
            to="/components/component_projects"
            v-bind="attrs"
            v-on="on"
            title=""
            right
            fab
            small
            color="orange white--text"
            style="margin: 5px"
          >
            <v-icon>mdi-cards-outline</v-icon>
          </v-btn>
        </template>
        <span>View Projects</span>
      </v-tooltip> -->
      <!-- 
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="OpenPSARecommendationEditor = true"
            v-bind="attrs"
            v-on="on"
            title=""
            right
            fab
            small
            color="success white--text"
            style="margin: 5px"
          >
            <v-icon>mdi-layers-outline</v-icon>
          </v-btn>
        </template>
        <span>View Sub-Components</span>
      </v-tooltip> -->

      <!-- <v-flex justify-xl-space-around xs12 md2 sm6> -->
      <!-- <AppealReminder
          @CloseNoticeDialog="OpenNoticeDialog = false"
          :OpenNoticeDialog="OpenNoticeDialog"
        /> -->

      <v-menu offset-y app>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" slot="activator" icon color="success"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn
          >
        </template>

        <v-list dense>
         
          <v-list-item link>
            <v-list-item-title
              ><v-icon style="height: 16px; font-size: 16px"
                >mdi-account-group-outline</v-icon
              >
              Add Attachments</v-list-item-title
            ></v-list-item
          >
          <v-list-item link>
            <v-list-item-title
              ><v-icon style="height: 16px; font-size: 16px"
                >mdi-publish</v-icon
              >
              Get Simple Report</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- </v-flex> -->
    </div>

    <ProjectAdd
      :addProjectDialog="addProjectDialog"
      :projectAddDialogData="projectAddDialogData"
    />

    <v-row class="mt-5">
      <!-- <v-card class="col-lg-4 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="text-dark pa-3">Project Categorization</h4>
            <hr class="text-dark" />
            <div class="table-responsive browser_users">
              <v-simple-table class="table mb-0">
                <template v-slot:default>
                  <thead class="thead-light">
                    <tr>
                      <th class="border-top-0">Category</th>
                      <th class="border-top-0">No.</th>
                      <th class="border-top-0">Cost</th>
                      <th class="border-top-0">(% Composition)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Uchukuzi</td>
                      <td>7<small class="text-muted"></small></td>
                      <td>2,683,900,000,000.0</td>
                      <td>13.63<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Nishati</td>
                      <td>0<small class="text-muted"></small></td>
                      <td>0</td>
                      <td>0.0<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Afya</td>
                      <td>3<small class="text-muted"></small></td>
                      <td>10,001,000,000,000.0</td>
                      <td>50.8<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Maji</td>
                      <td>0<small class="text-muted"></small></td>
                      <td>0</td>
                      <td>0.0<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Yael Ayers</td>
                      <td>2<small class="text-muted"></small></td>
                      <td>7,001,000,000,000.0</td>
                      <td>35.56<small class="text-success"> %</small></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </v-card> -->

      <v-card class="col-md-12">
        <v-data-table :headers="headers" :items="desserts">
          <template v-slot:item.calories="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}%
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu :key="item.name" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  icon
                  color="success"
                  ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list dense>
                <v-list-item link to="/components/project_details">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-eye</v-icon
                    >
                    View Project in Details</v-list-item-title
                  >
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item link to="">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-delete</v-icon
                    >
                    Delete Project</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <!-- <DocumentViwer v-if="OpenfileDialog==true" :docValue="docValue" :OpenfileDialog="OpenfileDialog" :title="title" @CloseDocumentViwer="OpenfileDialog=false"/> -->
  </v-container>
</template>

<script>
import PageTitle from "../../../digitaltp_shared/page-title.vue";
import DetailsTable from "./DetailsTable.vue";
// import MovementForm from "./MovementForm.vue";
import ProjectAdd from "./projectAdd.vue";
export default {
  components: {
    DetailsTable,
    // MovementForm,
    PageTitle,
    ProjectAdd,
  },
  data() {
    return {
      OpenNoticeDialog: false,
      OpenFeedbackEditor: false,
      OpenfileDialog: false,
      OpenDecisionEditor: false,
      filePreviewDialog: false,
      OpenPSARecommendationEditor: false,
      projectAddDialogData: false,
      OpenMovementsEditor: false,
      title: "",
      actionName: "PSA Analysis, Comments and Advice",
      appealTitle: "PSA Analysis, Comments and Advice",
      name: [],

      headers: [
        {
          text: "Project Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descriptions", value: "fat" },
        { text: "Project Budget", value: "carbs" },
        { text: "Disbused Amount", value: "protein" },
        { text: "Commulative Expenditure", value: "expenditure" },
        { text: "Status in (%)", value: "calories" },
        { text: "Actions", value: "actions" },
      ],

      desserts: [
        {
          name: "Innovation Center",
          calories: 20,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "$ 5.0 Mill",
          protein: "$ 4.0 Mill",
          expenditure: "$ 1.0 Mill",
          actions: "1%",
        },
        {
          name: "Statistical Information Management System",
          calories: 54,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "$ 6.7 Mill",
          protein: "$ 4.3 Mill",
          expenditure: "$ 2.4 Mill",
          actions: "1%",
        },
        {
          name: "e-Commerce",
          calories: 87,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "5.6",
          protein: "$ 4.3 Mill",
          expenditure: "$ 1.3 Mill",
          actions: "8%",
        },
        {
          name: "ICT Regulatory",
          calories: 26,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "$ 4.8 Mill",
          protein: "$ 3.9 Mill",
          expenditure: "$ 0.9 Mill",
          actions: "16%",
        },
        {
          name: "Dedicated Zonal Soft centers",
          calories: 26,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "$ 4.8 Mill",
          protein: "$ 3.9 Mill",
          expenditure: "$ 0.9 Mill",
          actions: "16%",
        },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
    };
  },
  methods: {
    openAppealAttachment(docname) {
      this.title = docname;
      this.OpenfileDialog = true;
    },

    getColor(calories) {
      if (calories < 50) return "red";
      else if (calories > 50 && calories < 70) return "orange";
      else return "green";
    },

    addProjectDialog(data) {
      this.projectAddDialogData = data;
    },
  },
  computed: {
    appeal() {
      // var appealList = this.$store.state.Store.listOf;
      // const  = appealList.find(appeal => appeal.UniqueId === this.appealId);
      return [];
    },
    appealId() {
      return this.$route.params.appealId;
    },
  },
  async mounted() {
    this.$store.dispatch("showLoadingPinner", true);
    await this.$store.dispatch("load");
    this.$store.dispatch("showLoadingPinner", false);
  },
};
</script>



<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style>
.node {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 40px;
}
.events-banner {
  padding: 5px;
  font-size: small;
  display: block;
}
.events-banner .prefix {
  color: #858585;
}
.events-banner .content {
  font-weight: bold;
  color: #273080;
}
.events-banner .value {
  color: #3a5831;
  font-size: larger;
}
.events-banner-event {
  background-color: #f9f9f9;
  margin: 2px;
  padding: 10px;
  display: inline-block;
  clear: left;
  width: auto;
}
.vue-xeditable-value {
  cursor: pointer;
  color: #17c;
  border-bottom: 1px dashed #07c;
  line-height: 22px;
}
h1 {
  margin: 0;
  text-align: center;
  color: #42b983;
}
h4 {
  margin-bottom: 0;
  color: #404040;
}
a {
  color: #07c;
}
pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}
.highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}
pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}
pre code,
pre tt {
  background-color: transparent;
  border: none;
}
</style>


<style>
#div1,
#div2 {
  float: left;
  width: 100px;
  height: 35px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}
</style>






