<template>
  <v-container fluid app>
    <!-- <div class="col-md-12 mb-3">
    </div> -->
      <page-title  :useBackBtn="true"></page-title>
    <v-row>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Total Project
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                12
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Completed Project
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                12
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Ongoing Project
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                12
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Pending Project
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                12
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-lg-4 col-md-12">
        <v-card elevation="1">
          <v-system-bar lights-out>12 Projects Summary</v-system-bar>
          <v-carousel
            cycle
            height="400"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
              <v-card :loading="loading">
                <template slot="progress">
                  <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-img
                  height="250"
                  src="https://image.architonic.com/prj2-3/20060856/tetsuo-kobori-architects-nicca-innovation-center-architonic-nic-203-01-arcit18.jpg"
                ></v-img>

                <v-card-title>Innovation Center</v-card-title>

                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <div class="grey--text ms-1">Completed by 20%</div>
                  </v-row>

                  <div class="my-4 text-subtitle-1">
                    Started on: 3 Feb, 2021 To: 3 Feb, 2022
                  </div>

                  <div class="my-1 text-subtitle-1">
                    1,000,000,000 Bill TZS PROJECT BUDGET
                  </div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>

      <v-col class="col-lg-8 col-md-12">
        <project-tranding></project-tranding>
      </v-col>

      <v-col class="col-lg-8 col-md-12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item.calories="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}%
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu :key="item.name" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  icon
                  color="success"
                  ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list dense>
                <v-list-item link to="/components/project_details">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-eye</v-icon
                    >
                    View Project in Details</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link to="/">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-plus</v-icon
                    >
                    Add Activities</v-list-item-title
                  ></v-list-item
                >
                <v-list-item link to="/">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-publish</v-icon
                    >
                    Get Simple Report</v-list-item-title
                  >
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item link to="/">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-delete</v-icon
                    >
                    Delete Project</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>

      <v-card class="col-lg-4 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mt-0 mb-3">Project Categorization</h4>
            <div class="table-responsive browser_users">
              <v-simple-table class="table mb-0">
                <template v-slot:default>
                  <thead class="thead-light">
                    <tr>
                      <th class="border-top-0">Category</th>
                      <th class="border-top-0">No.</th>
                      <th class="border-top-0">Cost</th>
                      <th class="border-top-0">(% Composition)</th>
                    </tr>
                    <!--end tr-->
                  </thead>
                  <tbody>
                    <tr>
                      <td>Uchukuzi</td>
                      <td>7<small class="text-muted"></small></td>
                      <td>2,683,900,000,000.0</td>
                      <td>13.63<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Nishati</td>
                      <td>0<small class="text-muted"></small></td>
                      <td>0</td>
                      <td>0.0<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Afya</td>
                      <td>3<small class="text-muted"></small></td>
                      <td>10,001,000,000,000.0</td>
                      <td>50.8<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Maji</td>
                      <td>0<small class="text-muted"></small></td>
                      <td>0</td>
                      <td>0.0<small class="text-success"> %</small></td>
                    </tr>

                    <tr>
                      <td>Yael Ayers</td>
                      <td>2<small class="text-muted"></small></td>
                      <td>7,001,000,000,000.0</td>
                      <td>35.56<small class="text-success"> %</small></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!--end table-->
            </div>
            <!--end /div-->
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ProjectTranding from "../../../me_projects/project_view/ProjectTranding.vue";
import pageTitle from "../../../digitaltp_shared/page-title.vue";
export default {
  components: {
    ProjectTranding,
    pageTitle,
  },
  data() {
    return {
      headers: [
        {
          text: "Project Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descriptions", value: "fat" },
        { text: "Project Budget", value: "carbs" },
        { text: "Disbused Amount", value: "protein" },
        { text: "Commulative Expenditure", value: "expenditure" },
        { text: "Status in (%)", value: "calories" },
        { text: "Actions", value: "actions" },
      ],
      desserts: [
        {
          name: "Innovation Center",
          calories: 20,
          fat: 6.0,
          carbs:
            "Establishment of the National ICT Professional and Innovation Center",
          protein: "$ 4.0 Mill",
          expenditure: "$ 1.0 Mill",
          actions: "1%",
        },
        {
          name: "Statistical Information Management System",
          calories: 54,
          fat: 9.0,
          carbs:
            "Development of National ICT Statistical Information Management System.",
          protein: "$ 4.3 Mill",
          expenditure: "$ 2.4 Mill",
          actions: "1%",
        },
        {
          name: "e-Commerce",
          calories: 87,
          fat: 3.7,
          carbs: "Tanzania Postal e-Commerce Initiatives.",
          protein: "$ 4.3 Mill",
          expenditure: "$ 1.3 Mill",
          actions: "8%",
        },
        {
          name: "ICT Regulatory",
          calories: 26,
          fat: 16.0,
          carbs:
            "Scanning of ICT Regulatory (Policy, Legal and Infrastructure) Environment.",
          protein: "$ 3.9 Mill",
          expenditure: "$ 0.9 Mill",
          actions: "16%",
        },
        {
          name: "Dedicated Zonal Soft centers",
          calories: 26,
          fat: 16.0,
          carbs:
            "Scanning of ICT Regulatory (Policy, Legal and Infrastructure) Environment.",
          protein: "$ 3.9 Mill",
          expenditure: "$ 0.9 Mill",
          actions: "16%",
        },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  methods: {
    getColor(calories) {
      if (calories < 50) return "red";
      else if (calories > 50 && calories < 70) return "orange";
      else return "green";
    },
  },
};
</script>
